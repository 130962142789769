import React from 'react';

const Footer = () => {
    return (
        <div className="footer">
            <p>&copy;2024 Central Time Clock. All rights reserved.</p>
        </div>
    );
};

export default Footer;
