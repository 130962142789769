import React, { Component } from 'react';

import Header from '../../components/header/Header';
import Footer from '../../components/footer/Footer';

class Pricing extends Component {
    render() {
        return (
            <>
                <Header />
                <main className="single-page">
                    <section className="pricing">
                        <div className="container">
                            <h1>
                                <span>pricing</span>
                                The right plan for your needs
                            </h1>
                            <div className="pricing-wrapper">
                                <div className="pricing-block">
                                    <h2>Silver</h2>
                                    <h3>$30 <span>+ $2.75 Per Employee / Month</span></h3>
                                    <div href="#" className="btn primary">
                                        buy now
                                    </div>
                                    
                                    <span>
                                        <b>Features</b>
                                        <ul>
                                            <li>Integrate with Payroll</li>
                                            <li>Real-time reports</li>
                                            <li>See who's working</li>
                                            <li>Timesheet signatures</li>
                                            <li>PTO management</li>
                                            <li>Employee handbook management</li>
                                            <li>Alerts and notifications</li>
                                            <li>Mobile or desktop</li>
                                            <li>Geofencing</li>
                                        </ul>
                                    </span>
                                </div>
                                <div className="pricing-block">
                                    <h2>Gold</h2>
                                    <h3>$40 <span>+ 3.75 Per Employee / Month</span></h3>
                                    <div href="#" className="btn primary">
                                        buy now
                                    </div>
                                    
                                    <span>
                                        <b>Features</b>

                                        <ul>
                                            <li>
                                                <b>Everything from Silver plan</b>
                                            </li>
                                            <li>GPS tracking</li>
                                            <li>Mileage tracking</li>
                                            <li>Quickbooks Integration</li>
                                            <li>Custom reporting</li>
                                            <li>Custom field data collection</li>
                                            <li>Timed photo attachments</li>
                                        </ul>
                                    </span>
                                </div>
                                <div className="pricing-block">
                                    <h2>Platinum</h2>
                                    <h3>$50 <span>+ $4.75 Per Employee / Month</span></h3>
                                    <div href="#" className="btn primary">
                                        buy now
                                    </div>
                                    
                                    <span>
                                        <b>Features</b>
                                        <ul>
                                            <li>
                                                <b>Everything from Gold plan</b>
                                            </li>
                                            <li>Custom daily, wkly, mnthly, questions</li>
                                            <li>Accident Injury & Incident reporting</li>
                                            <li>Track progress to Plan</li>
                                            <li>Project estimates vs actuals reporting</li>
                                            <li>
                                                Custom departments and job costing, or projects and
                                                cost codes
                                            </li>
                                        </ul>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </section>
                    <Footer />
                </main>
            </>
        );
    }
}

export default Pricing;
