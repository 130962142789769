import React, { useState } from 'react';
import VisibilitySensor from 'react-visibility-sensor';

import './animated-icon.scss'

const AnimatedIcon = ({ icon }) => {
    const [visible, setVisible] = useState(false);

    const handleViewportVisibilityChange = (isVisible) => {
        setVisible(isVisible);
    };

    const renderSvg = () => {
        const className = visible ? 'icon animation' : 'icon';
        switch (icon) {
            case 'icon-consolidated-reporting':
                return (
                    <svg
                        className={className}
                        xmlns="http://www.w3.org/2000/svg"
                        width="94.44"
                        height={100}
                        viewBox="0 0 94.44 100"
                    >
                        <path
                            fill="none"
                            stroke="#8a8d8e"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={3}
                            d="M2172.81,1640.91h-29.53v-68.29l16.61-16.61h46.14l18.46,16.61v18.46"
                            transform="translate(-2141.28 -1554)"
                        />
                        <path
                            fill="none"
                            stroke="#8a8d8e"
                            strokeWidth={3}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M2145.13,1572.69h77.51"
                            transform="translate(-2141.28 -1554)"
                        />
                        <path
                            fill="none"
                            stroke="#8a8d8e"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={3}
                            d="M2206.96,1598.46a26.765,26.765,0,1,1-26.77,26.76A26.759,26.759,0,0,1,2206.96,1598.46Z"
                            transform="translate(-2141.28 -1554)"
                        />
                        <path
                            fill="none"
                            stroke="#202c5e"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={3}
                            d="M2206.19,1607.54v20.3l12.92,5.54"
                            transform="translate(-2141.28 -1554)"
                        />
                        <path
                            fill="none"
                            stroke="#202c5e"
                            strokeWidth={3}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M2178.35,1556.01l-5.54,16.61v14.77h22.15v-14.77l-5.54-16.61h-11.07Z"
                            transform="translate(-2141.28 -1554)"
                        />
                    </svg>
                );
            case 'icon-data-integration':
                return (
                    <svg
                        className={className}
                        xmlns="http://www.w3.org/2000/svg"
                        width="113.76"
                        height="101.06"
                        viewBox="0 0 113.76 101.06"
                    >
                        <path
                            fill="none"
                            stroke="#8a8d8e"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={3}
                            d="M2504.11,1592.74l54.89-39.52,54.89,39.52L2559,1632.26Z"
                            transform="translate(-2502.12 -1551.22)"
                        />
                        <path
                            fill="none"
                            stroke="#202c5e"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={3}
                            d="M2509.5,1614.7l50.5,35.13,48.3-35.13"
                            transform="translate(-2502.12 -1551.22)"
                        />
                    </svg>
                );
            case 'icon-field-access':
                return (
                    <svg
                        className={className}
                        xmlns="http://www.w3.org/2000/svg"
                        width="104.72"
                        height="102.72"
                        viewBox="0 0 104.72 102.72"
                    >
                        <path
                            fill="none"
                            stroke="#8a8d8e"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={3}
                            d="M1764.83,1615.9v-37.82s-0.8-10.8,12.2-10.8h37.04s20.17,2.98,28.46,11.25,17.17,16.29,17.17,37.82Z"
                            transform="translate(-1762.81 -1552.22)"
                        />
                        <path
                            fill="none"
                            stroke="#202c5e"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={3}
                            d="M1792.58,1628.25s5.89,10.68,20.45,10.68,18.81-10.68,18.81-10.68"
                            transform="translate(-1762.81 -1552.22)"
                        />
                        <path
                            fill="none"
                            stroke="#8a8d8e"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={3}
                            d="M1775.67,1617.82s2.06,35.11,38.36,35.11c33.46,0,35.28-35.11,35.28-35.11"
                            transform="translate(-1762.81 -1552.22)"
                        />
                        <path
                            fill="none"
                            stroke="#8a8d8e"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={3}
                            d="M1777.48,1567.73v-9.01s-0.83-4.5,4.52-4.5h28.91a7.375,7.375,0,0,1,5.87,4.05c2.06,3.93,5.88,11.26,5.88,11.26"
                            transform="translate(-1762.81 -1552.22)"
                        />
                        <path
                            fill="none"
                            stroke="#8a8d8e"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={3}
                            d="M1836.92,1573.13l13.55-13.96s7.52-4.66,12.2,0,2.76,11.66.45,13.96-11.29,11.7-11.29,11.7"
                            transform="translate(-1762.81 -1552.22)"
                        />
                        <path
                            fill="none"
                            stroke="#8a8d8e"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={3}
                            d="M1793.74,1615.45s-0.46-19.81,18.98-19.81,19.42,19.81,19.42,19.81"
                            transform="translate(-1762.81 -1552.22)"
                        />
                        <path
                            fill="none"
                            stroke="#202c5e"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={3}
                            d="M1777.48,1591.59h8.59"
                            transform="translate(-1762.81 -1552.22)"
                        />
                        <path
                            fill="none"
                            strokeLinecap="round"
                            stroke="#202c5e"
                            strokeWidth={3}
                            d="M1777.48,1582.58h8.59"
                            transform="translate(-1762.81 -1552.22)"
                        />
                        <path
                            fill="#8a8d8e"
                            className="cls-4"
                            d="M1819.04,1615.9a6.325,6.325,0,0,0-12.65,0"
                            transform="translate(-1762.81 -1552.22)"
                        />
                    </svg>
                );
            default:
                return null;
        }
    };

    return (
        <VisibilitySensor onChange={handleViewportVisibilityChange}>{renderSvg()}</VisibilitySensor>
    );
};

export default AnimatedIcon;
