import React from 'react';
import { Link } from 'react-router-dom';

import Header from '../../components/header/Header';
import Footer from '../../components/footer/Footer';

import helmetImg from '../../images/helmet.png';
class PageNotFound extends React.Component {
    render() {
        return (
            <>
                <Header />
                <main className="error-page">
                    <section className="banner">
                        <div className="container">
                            <img className="error-img" alt="error" src={helmetImg} />
                            <div className="wrapper">
                                <h2>Page Not Found</h2>
                                <p>
                                    Looks like you've followed a broken link or entered a URL that
                                    doesn't exist on this site.
                                </p>
                                <Link to="/#" className="btn primary">
                                    Go Home
                                </Link>
                            </div>
                        </div>
                    </section>
                    <Footer/>
                </main>
            </>
        );
    }
}

export default PageNotFound;
