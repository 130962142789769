import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import DatePicker from 'react-datepicker';
import Header from '../../components/header/Header';
import Footer from '../../components/footer/Footer';

import { signUp, login, getAccessToken, getOvertimeRules } from '../../actions/accountActions';
import parseValidation from '../../helpers/parse-validation';
import 'react-datepicker/dist/react-datepicker.css';

const SignUp = (props) => {
    const [data, setData] = useState({
        companyName: '',
        companyId: '',
        adminName: '',
        adminEmail: '',
        password: '',
        passwordRepeat: '',
        payPeriodStartDate: '',
        adminJPUserName: '',
        adminJPPassword: '',
        timezone: 'America/Denver',
        payPeriodType: 'weekly',
        overTimeRuleId: '',
        sendEmail: true,
        sendUserEmailDate: new Date(),
        isColoradoJP: true,
        addonFeatures: {
            tracking: false,
            scheduling: false,
            jobsAndProjects: false,
            reimbursement: false,
            accidentReporting: false
        }
    });
    const [errors, setErrors] = useState({
        companyName: '',
        companyId: '',
        adminName: '',
        adminEmail: '',
        password: '',
        passwordRepeat: '',
        payPeriodStartDate: '',
        adminJPUserName: '',
        adminJPPassword: '',
        loginError: '',
        overTimeRuleId: ''
    });
    const [overTimeRules, setOverTimeRules] = useState([]);
    const [token, setToken] = useState(getAccessToken());
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('Something went wrong, please try again');
    const [loading, setLoading] = useState(false);

    const inputLabels = {};

    useEffect(() => {
        if (!token) {
            return;
        }
        let ignore = false;
        getOvertimeRules().then((result) => {
            if (!ignore) {
                if (!result.error) {
                    setOverTimeRules(result.data.data);
                }
            }
        });
        return () => {
            ignore = true;
        };
    }, [token]);

    const setInputLabelRef = (ref) => {
        if (ref) {
            const { id } = ref;
            inputLabels[id] = ref;
        }
    };

    const handleFieldChange = (ev) => {
        const { id, value, checked, type } = ev.target;
        const keys = id.split('.');
        if (keys.length > 1) {
            setData((data) => ({
                ...data,
                [keys[0]]: {
                    ...data[keys[0]],
                    [keys[1]]: type === 'checkbox' ? checked : value
                }
            }));
        } else {
            setData({
                ...data,
                [id]: type === 'checkbox' ? checked : value
            });
        }

        setErrors({
            ...errors,
            [ev.target.id]: ''
        });
    };

    const handleSelectPayPeriodStartDate = (date) => {
        setData({
            ...data,
            payPeriodStartDate: date
        });

        setErrors({
            ...errors,
            payPeriodStartDate: ''
        });
    };

    const handleEmailDateSelected = (date) => {
        setData({
            ...data,
            sendUserEmailDate: date
        });
    };

    const validateData = (adminLogin) => {
        const {
            password,
            passwordRepeat,
            payPeriodStartDate,
            adminJPPassword,
            adminJPUserName,
            companyName,
            adminName,
            companyId,
            adminEmail,
            overTimeRuleId
        } = data;
        let isValid = true;

        const newErrors = {};

        if (adminLogin) {
            if (!adminJPUserName) {
                newErrors.adminJPUserName = 'Please enter a valid user name';
            }

            if (!adminJPPassword) {
                newErrors.adminJPPassword = 'Please enter a valid password';
            }
        } else {
            if (!companyName) {
                newErrors.companyName = 'Please enter a valid company name';
                inputLabels.companyName.scrollIntoView();
                isValid = false;
            }

            const parsedCompanyId = parseInt(companyId, 10);
            if (isNaN(parsedCompanyId)) {
                newErrors.companyId = 'Please enter a valid company ID';
                if (isValid) {
                    inputLabels.companyId.scrollIntoView();
                }
                isValid = false;
            }

            if (!adminName) {
                newErrors.adminName = 'Please enter a valid admin name';
                if (isValid) {
                    inputLabels.adminName.scrollIntoView();
                }
                isValid = false;
            }

            if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(adminEmail) || !adminEmail) {
                newErrors.adminEmail = 'Please enter a valid email';
                if (isValid) {
                    inputLabels.adminEmail.scrollIntoView();
                }
                isValid = false;
            }

            if (!password) {
                newErrors.password = 'Please enter a password';
                if (isValid) {
                    inputLabels.password.scrollIntoView();
                }
                isValid = false;
            }

            if (!passwordRepeat) {
                newErrors.passwordRepeat = 'Please repeat your password';
                if (isValid) {
                    inputLabels.passwordRepeat.scrollIntoView();
                }
                isValid = false;
            }

            if (password !== passwordRepeat) {
                newErrors.passwordRepeat = 'Please make sure both passwords are match';
                if (isValid) {
                    inputLabels.passwordRepeat.scrollIntoView();
                }
                isValid = false;
            }

            if (!payPeriodStartDate) {
                newErrors.payPeriodStartDate = 'Please select a pay period start date';
                if (isValid) {
                    inputLabels.payPeriodStartDate.scrollIntoView();
                }
                isValid = false;
            }

            if (!overTimeRuleId) {
                newErrors.overTimeRuleId = 'Please select a overtime rule';

                if (isValid) {
                    inputLabels.overTimeRuleId.scrollIntoView();
                }
                isValid = false;
            }
        }

        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
        }

        return isValid;
    };

    const handleAdminLogin = (event) => {
        event.preventDefault();

        if (validateData(true)) {
            login({
                email: data.adminJPUserName,
                password: data.adminJPPassword
            }).then(({ error, data: loginData }) => {
                if (error) {
                    return setErrors({
                        ...errors,
                        loginError: error.message
                    });
                }

                if (loginData.user.email === 'cod_time_tracking_admin@jaybirdgroup.com') {
                    setData({
                        ...data,
                        isColoradoJP: true
                    });
                } else {
                    setData({
                        ...data,
                        isColoradoJP: false
                    });
                }

                setToken(loginData.token);
            });
        }
    };

    const handleFormSubmit = (ev) => {
        ev.preventDefault();

        if (validateData(false)) {
            setLoading(true);
            setError(false);
            setErrorMessage('');

            const companyInfo = {
                companyName: data.companyName,
                companyId: parseInt(data.companyId, 10),
                adminName: data.adminName,
                adminEmail: data.adminEmail,
                password: data.password,
                passwordRepeat: data.passwordRepeat,
                payPeriodType: data.payPeriodType,
                payPeriodStartDate:
                    format(data.payPeriodStartDate, 'yyyy-MM-dd') + 'T00:00:00.000Z',
                timezone: data.timezone,
                phoneNumber: '',
                overTimeRuleId: data.overTimeRuleId,
                isSendUserEmails: data.sendEmail,
                sendUserEmailDate: data.sendUserEmailDate.toISOString(),
                isColoradoJP: data.isColoradoJP,
                addonFeatures: data.addonFeatures
            };

            signUp(companyInfo).then(({ error, data }) => {
                setLoading(false);
                window.scrollTo(0, 0);
                if (error) {
                    const validation = parseValidation(data);
                    setError(true);
                    if (Object.keys(validation.help).length > 0) {
                        setErrors({
                            ...errors,
                            ...validation.help
                        });

                        setErrorMessage('Please fix all errors below');
                    } else {
                        setErrorMessage('Something went wrong, please try again');
                    }
                } else {
                    setSuccess(true);
                }
            });
        }

        return false;
    };

    let form = null;
    let successConfirmation = null;

    if (success) {
        successConfirmation = (
            <div className="confirmation">
                <h1>Well done!</h1>
                <p>Your free trial period successfully started. Please, log in.</p>
                <div className="btns-wrapper">
                    <Link to="/" className="btn primary">
                        Go Home
                    </Link>
                </div>
            </div>
        );
    } else {
        if (!token) {
            form = (
                <>
                    <form className="form" onSubmit={handleAdminLogin}>
                        <h2>Login</h2>
                        {errors.loginError && (
                            <div className="error-wrapper">
                                <span className="error">{errors.loginError}</span>
                            </div>
                        )}

                        <div className="fieldset">
                            <label
                                htmlFor="adminJPUserName"
                                className={errors.adminJPUserName ? 'error' : ''}
                            >
                                User Name*
                            </label>
                            <input
                                type="text"
                                id="adminJPUserName"
                                className={errors.adminJPUserName ? 'error' : ''}
                                value={data.adminJPUserName}
                                onChange={handleFieldChange}
                            />
                            {errors.adminJPUserName && (
                                <span className="error">{errors.adminJPUserName}</span>
                            )}
                        </div>
                        <div className="fieldset">
                            <label
                                htmlFor="adminJPPassword"
                                className={errors.adminJPPassword ? 'error' : ''}
                            >
                                Password*
                            </label>
                            <input
                                type="password"
                                id="adminJPPassword"
                                className={errors.adminJPPassword ? 'error' : ''}
                                value={data.adminJPPassword}
                                onChange={handleFieldChange}
                            />
                            {errors.adminJPPassword && (
                                <span className="error">{errors.adminJPPassword}</span>
                            )}
                        </div>
                        <div className="btns-wrapper">
                            <button
                                type="submit"
                                className="btn primary full-width"
                                disabled={loading}
                            >
                                login
                            </button>
                        </div>
                    </form>
                </>
            );
        } else {
            form = (
                <>
                    <form className="form" onSubmit={handleFormSubmit}>
                        <h2>Create Account</h2>
                        {error && (
                            <div className="error-wrapper">
                                <span className="error">{errorMessage}</span>
                            </div>
                        )}
                        <div className="fieldset">
                            <label
                                ref={setInputLabelRef}
                                id="companyName"
                                htmlFor="companyName"
                                className={errors.companyName ? 'error' : ''}
                            >
                                Company Name*
                            </label>
                            <input
                                type="text"
                                id="companyName"
                                className={errors.companyName ? 'error' : ''}
                                value={data.companyName}
                                onChange={handleFieldChange}
                            />
                            {errors.companyName && (
                                <span className="error">{errors.companyName}</span>
                            )}
                        </div>
                        <div className="fieldset">
                            <label
                                ref={setInputLabelRef}
                                id="companyId"
                                htmlFor="companyId"
                                className={errors.companyId ? 'error' : ''}
                            >
                                Company ID (EPID)*
                            </label>
                            <input
                                type="text"
                                id="companyId"
                                className={errors.companyId ? 'error' : ''}
                                value={data.companyId}
                                onChange={handleFieldChange}
                            />
                            {errors.companyId && <span className="error">{errors.companyId}</span>}
                        </div>
                        <div className="fieldset">
                            <label
                                htmlFor="payPeriodType"
                                className={errors.payPeriodType ? 'error' : ''}
                            >
                                Pay Period Type*
                            </label>
                            <select
                                id="payPeriodType"
                                name="payPeriodType"
                                value={data.payPeriodType}
                                onChange={handleFieldChange}
                            >
                                <option value="weekly">Weekly</option>
                                <option value="bi-weekly">Bi-Weekly</option>
                                <option value="semi-monthly">Semi-Monthly</option>
                            </select>
                        </div>

                        <div className="fieldset">
                            <label
                                ref={setInputLabelRef}
                                id="adminName"
                                htmlFor="adminName"
                                className={errors.adminName ? 'error' : ''}
                            >
                                Admin Name*
                            </label>
                            <input
                                type="text"
                                id="adminName"
                                className={errors.adminName ? 'error' : ''}
                                value={data.adminName}
                                onChange={handleFieldChange}
                            />
                            {errors.adminName && <span className="error">{errors.adminName}</span>}
                        </div>
                        <div className="fieldset">
                            <label
                                id="adminEmail"
                                ref={setInputLabelRef}
                                htmlFor="adminEmail"
                                className={errors.adminEmail ? 'error' : ''}
                            >
                                Admin Email*
                            </label>
                            <input
                                type="text"
                                id="adminEmail"
                                className={errors.adminEmail ? 'error' : ''}
                                value={data.adminEmail}
                                onChange={handleFieldChange}
                            />
                            {errors.adminEmail && (
                                <span className="error">{errors.adminEmail}</span>
                            )}
                        </div>
                        <div className="fieldset">
                            <label
                                ref={setInputLabelRef}
                                id="password"
                                htmlFor="password"
                                className={errors.password ? 'error' : ''}
                            >
                                Password*
                            </label>
                            <input
                                type="password"
                                id="password"
                                className={errors.password ? 'error' : ''}
                                value={data.password}
                                onChange={handleFieldChange}
                            />
                            {errors.password && <span className="error">{errors.password}</span>}
                        </div>
                        <div className="fieldset">
                            <label
                                ref={setInputLabelRef}
                                id="passwordRepeat"
                                htmlFor="passwordRepeat"
                                className={errors.passwordRepeat ? 'error' : ''}
                            >
                                Password Repeat*
                            </label>
                            <input
                                type="password"
                                id="passwordRepeat"
                                className={errors.passwordRepeat ? 'error' : ''}
                                value={data.passwordRepeat}
                                onChange={handleFieldChange}
                            />
                            {errors.passwordRepeat && (
                                <span className="error">{errors.passwordRepeat}</span>
                            )}
                        </div>

                        <div className="fieldset">
                            <label
                                ref={setInputLabelRef}
                                id="payPeriodStartDate"
                                htmlFor="payPeriodStartDate"
                                className={errors.payPeriodStartDate ? 'error' : ''}
                            >
                                Pay period Start Date
                            </label>
                            <DatePicker
                                showIcon
                                selected={data.payPeriodStartDate}
                                className={errors.payPeriodStartDate ? 'error' : ''}
                                maxDate={new Date()}
                                showMonthDropdown
                                placeholderText="mm/dd/yyyy"
                                todayButton="Today"
                                calendarStartDay={1}
                                popperModifiers={[
                                    {
                                        name: 'offset',
                                        options: {
                                            offset: [100, 0]
                                        }
                                    }
                                ]}
                                onChange={handleSelectPayPeriodStartDate}
                            />

                            {errors.payPeriodStartDate && (
                                <span className="error">{errors.payPeriodStartDate}</span>
                            )}
                        </div>

                        <div className="fieldset">
                            <label htmlFor="timezone" className={errors.timezone ? 'error' : ''}>
                                Time Zone*
                            </label>
                            <select
                                id="timezone"
                                name="timezone"
                                value={data.timezone}
                                onChange={handleFieldChange}
                            >
                                <option value="America/New_York">America/New York</option>
                                <option value="America/Denver">America/Denver</option>
                                <option value="Pacific/Honolulu">Pacific/Honolulu</option>
                                <option value="America/Adak">America/Adak</option>
                                <option value="America/Anchorage">America/Anchorage</option>
                                <option value="America/Los_Angeles">America/Los_Angeles</option>
                                <option value="America/Phoenix">America/Phoenix</option>
                                <option value="America/Chicago">America/Chicago</option>
                            </select>
                        </div>

                        <div className="fieldset">
                            <label
                                ref={setInputLabelRef}
                                id="overTimeRuleId"
                                htmlFor="overTimeState"
                                className={errors.overTimeRuleId ? 'error' : ''}
                            >
                                Over Time Rule*
                            </label>
                            <select
                                id="overTimeRuleId"
                                className={errors.overTimeRuleId ? 'error' : ''}
                                name="overTimeRuleId"
                                value={data.overTimeRuleId}
                                onChange={handleFieldChange}
                            >
                                <option value="">- Please select over time rule -</option>
                                {overTimeRules.map((overTimeRule) => (
                                    <option key={overTimeRule.id} value={overTimeRule.id}>
                                        {overTimeRule.Name}
                                    </option>
                                ))}
                            </select>
                            {errors.overTimeRuleId && (
                                <span className="error">{errors.overTimeRuleId}</span>
                            )}
                        </div>

                        <div className="fieldset checkbox">
                            <input
                                type="checkbox"
                                id="sendEmail"
                                checked={data.sendEmail}
                                onChange={handleFieldChange}
                            />
                            <label htmlFor="sendEmail">Send Email To Users</label>
                        </div>

                        <div className="fieldset">
                            <label htmlFor="payPeriodType">When to Send Emails*</label>
                            <DatePicker
                                showIcon
                                selected={data.sendUserEmailDate}
                                minDate={new Date()}
                                showMonthDropdown
                                placeholderText="mm/dd/yyyy"
                                todayButton="Today"
                                calendarStartDay={1}
                                popperModifiers={[
                                    {
                                        name: 'offset',
                                        options: {
                                            offset: [100, 0]
                                        }
                                    }
                                ]}
                                onChange={handleEmailDateSelected}
                            />
                        </div>

                        <div className="fieldset checkbox">
                            <input
                                type="checkbox"
                                id="isColoradoJP"
                                checked={data.isColoradoJP}
                                onChange={handleFieldChange}
                            />
                            <label htmlFor="isColoradoJP">Is HQ Journey</label>
                        </div>

                        <label>Add-Ons:</label>
                        <div className="checkbox-group">
                            <div>
                                <div className="fieldset checkbox">
                                    <input
                                        type="checkbox"
                                        id="addonFeatures.tracking"
                                        checked={data.addonFeatures.tracking}
                                        onChange={handleFieldChange}
                                    />
                                    <label htmlFor="addonFeatures.tracking">Tracking</label>
                                </div>
                                <div className="fieldset checkbox">
                                    <input
                                        type="checkbox"
                                        id="addonFeatures.scheduling"
                                        checked={data.addonFeatures.scheduling}
                                        onChange={handleFieldChange}
                                    />
                                    <label htmlFor="addonFeatures.scheduling">Scheduling</label>
                                </div>
                                <div className="fieldset checkbox">
                                    <input
                                        type="checkbox"
                                        id="addonFeatures.accidentReporting"
                                        checked={data.addonFeatures.accidentReporting}
                                        onChange={handleFieldChange}
                                    />
                                    <label htmlFor="addonFeatures.accidentReporting">
                                        Accident Reporting
                                    </label>
                                </div>
                            </div>
                            <div>
                                <div className="fieldset checkbox">
                                    <input
                                        type="checkbox"
                                        id="addonFeatures.jobsAndProjects"
                                        checked={data.addonFeatures.jobsAndProjects}
                                        onChange={handleFieldChange}
                                    />
                                    <label htmlFor="addonFeatures.jobsAndProjects">
                                        Jobs & Projects
                                    </label>
                                </div>
                                <div className="fieldset checkbox">
                                    <input
                                        type="checkbox"
                                        id="addonFeatures.reimbursement"
                                        checked={data.addonFeatures.reimbursement}
                                        onChange={handleFieldChange}
                                    />
                                    <label htmlFor="addonFeatures.reimbursement">
                                        Reimbursement
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="btns-wrapper">
                            <button
                                type="submit"
                                className="btn primary full-width"
                                disabled={loading}
                            >
                                Create Account
                            </button>
                        </div>
                    </form>
                </>
            );
        }
    }

    return (
        <>
            <Header />
            <main>
                <section className="center">
                    <div className="container">
                        <div className="form-wrapper">
                            {form}
                            {successConfirmation}
                        </div>
                    </div>
                </section>
                <Footer />
            </main>
        </>
    );
};

export default SignUp;
