import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import logoImg from '../../images/ctc-clock.svg';

import { getAccessToken, clearAccessToken } from '../../actions/accountActions';
import { getDxpUrl, getAppUrl } from '../../helpers/utils';

class Header extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showLoginOptions: false
        };
    }

    handleLogoutClick = () => {
        clearAccessToken();
        window.location = '/sign-up';
    };

    render() {
        const token = getAccessToken();
        return (
            <header>
                <div className="header">
                    <div className="container">
                        <div className="brand-wrapper">
                            <Link to="/" className="brand">
                                <img className="logo" alt="clock-on-the-dot" src={logoImg} />
                            </Link>
                        </div>

                        <div className="menu">
                            <Link to="/pricing" className="menu-item">
                                Pricing
                            </Link>
                            <a href={getDxpUrl()} className="login-btn">
                                Admin Login
                            </a>
                            <a href={getAppUrl()} className="login-btn">
                                User Login
                            </a>

                            {token ? (
                                <Link
                                    href="#"
                                    className="sign-btn"
                                    onClick={this.handleLogoutClick}
                                >
                                    JP Logout
                                </Link>
                            ) : (
                                <Link to="/sign-up" className="sign-btn">
                                    JP Login
                                </Link>
                            )}
                        </div>
                    </div>
                </div>
            </header>
        );
    }
}

export default Header;
