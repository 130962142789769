import { getDxpUrl } from '../helpers/utils';

function getDxpApiUrl() {
    return `${getDxpUrl()}/api`;
}

function appendFormData(formData, rootKey, data) {
    for (const dataKey of Object.keys(data)) {
        const value = data[dataKey];

        if (typeof value === 'object') {
            appendFormData(formData, dataKey, value);
        } else {
            let key = dataKey;
            if (rootKey) {
                key = `${rootKey}.${dataKey}`;
            }
            formData.append(key, data[dataKey]);
        }
    }
}

export function setAccessToken(token) {
    sessionStorage.setItem('token', token);
}

export function getAccessToken() {
    return sessionStorage.getItem('token');
}

export function clearAccessToken() {
    return sessionStorage.removeItem('token');
}

export function resetPassword(data = {}) {
    const { resetKey, resetEmail, password, passwordRepeat } = data;
    let url = `${getDxpApiUrl()}/login/reset`;
    return fetch(url, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            key: resetKey,
            email: resetEmail,
            password: password,
            passwordRepeat: passwordRepeat
        })
    })
        .then((response) => {
            return response;
        })
        .catch((err) => {
            console.log(err);
            return null;
        });
}

export async function login(data = {}) {
    const { email, password } = data;
    let url = `${getDxpApiUrl()}/login`;

    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                username: email,
                password,
                settingFields: ['logoUrl', 'sources']
            })
        });

        const data = await response.json();
        const allowedToAddCompanies = data.userPermissions['add-companies'] ? true : false;
        if (response.ok) {
            if (!allowedToAddCompanies) {
                return { error: new Error('Access Denied'), data: null };
            }
            setAccessToken(data.token);
            return { error: null, data };
        }

        return { error: new Error(data.message), data: null };
    } catch (error) {
        console.log(error);
        return { error: new Error('An error has occurred please try again'), data: null };
    }
}

export async function signUp(data = {}) {
    let url = `${getDxpApiUrl()}/time-tracking/sign-up`;

    const formData = new FormData();
    appendFormData(formData, null, data);

    const token = getAccessToken();
    const headers = new Headers({
        Authorization: `Bearer ${token}`,
        Accept: 'application/json'
    });

    try {
        const response = await fetch(url, {
            method: 'POST',
            headers,
            body: formData
        });

        const data = await response.json();
        if (response.ok) {
            return { error: null, data };
        }

        return { error: new Error(data.message), data };
    } catch (error) {
        console.log(error);
        return { error: new Error('An error has occurred please try again'), data: null };
    }
}

export async function getOvertimeRules() {
    const overtimeRulesSourceId = '6617b9c0147f1e50afbeb36e';
    let url = `${getDxpApiUrl()}/sources/${overtimeRulesSourceId}/data`;

    const token = getAccessToken();
    const headers = new Headers({
        Authorization: `Bearer ${token}`,
        Accept: 'application/json'
    });

    try {
        const response = await fetch(url, {
            method: 'GET',
            headers
        });

        const data = await response.json();
        if (response.ok) {
            return { error: null, data };
        }

        return { error: new Error(data.message), data };
    } catch (error) {
        console.log(error);
        return { error: new Error('An error has occurred please try again'), data: null };
    }
}
