import React from 'react';
import Slider from 'react-slick';

import Header from '../../components/header/Header';
import Footer from '../../components/footer/Footer';
import AnimatedIcon from '../../components/animated-icon/AnimatedIcon';

import googleImg from '../../images/google-play-badge.svg';
import appleImg from '../../images/apple-play-badge.svg';
import buildingImg from '../../images/banner-img.png';
import buildingImgMobile from '../../images/banner-img-mobile.png';
import phoneTimeTracking1Img from '../../images/phone-time-tracking-1.png';
import phoneTimeTracking2Img from '../../images/phone-time-tracking-2.png';
import phoneDailyReporting1Img from '../../images/phone-daily-reporting-1.png';
import phoneDailyReporting2Img from '../../images/phone-daily-reporting-2.png';
import phoneToolBoxTalks1Img from '../../images/phone-toolbox-talks-1.png';
import phoneToolBoxTalks2Img from '../../images/phone-toolbox-talks-2.png';
import phoneChangeOrder1Img from '../../images/phone-change-order-1.png';
import phoneChangeOrder2Img from '../../images/phone-change-order-2.png';
import phoneTimeAndMaterials1Img from '../../images/phone-time-and-materials-1.png';
import phoneTimeAndMaterials2Img from '../../images/phone-time-and-materials-2.png';
import phoneAccident1Img from '../../images/phone-accident-1.png';
import phoneAccident2Img from '../../images/phone-accident-2.png';

class Home extends React.Component {
    constructor() {
        super();
        this.aboutSectionRef = React.createRef();
        this.state = {
            nav1: null,
            nav2: null,
            isModalVisible: false
        };
    }

    componentDidMount() {
        this.setState({
            nav1: this.slider1,
            nav2: this.slider2
        });
    }

    scrollToAboutSection = () => {
        window.scrollTo(0, this.aboutSectionRef.current.offsetTop);
    };

    render() {
        const settingsSlider = {
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
            fade: true,
            draggable: false,
            asNavFor: '.list',
            autoplay: false,
            autoplaySpeed: 0,
            adaptiveHeight: true,
            responsive: [
                {
                    breakpoint: 800,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        dots: true,
                        draggable: true,
                        swipe: true
                    }
                }
            ]
        };
        const settingsList = {
            slidesToShow: 6,
            slidesToScroll: 1,
            asNavFor: '.slider',
            dots: false,
            centerMode: false,
            focusOnSelect: true,
            draggable: false,
            autoplay: false,
            beforeChange: () => {
                this.scrollToAboutSection();
            }
        };

        return (
            <>
                <Header />
                <main>
                    <section className="banner">
                        <div className="container">
                            <img className="banner-img" alt="building backdrop" src={buildingImg} />
                            <img
                                className="banner-img mobile"
                                alt="building backdrop"
                                src={buildingImgMobile}
                            />

                            <div className="wrapper">
                                <h1>
                                    The time clock solution for simple or complicated time
                                    management needs
                                </h1>
                                <p>
                                    Technology made easy for employers and employees, so your entire
                                    team can focus on their expertise
                                </p>
                                <div>
                                    <small>Or download it from </small>
                                    <div className="buttons-wrapper">
                                        <a
                                            className="store-btn"
                                            href="/"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            <img
                                                className="product-img"
                                                alt="google play store"
                                                src={googleImg}
                                            />
                                        </a>
                                        <a
                                            className="store-btn"
                                            href="/"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            <img
                                                className="product-img"
                                                alt="app store"
                                                src={appleImg}
                                            />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="about" ref={this.aboutSectionRef}>
                        <div className="container">
                            <Slider
                                className="list"
                                {...settingsList}
                                asNavFor={this.state.nav2}
                                ref={(slider) => (this.slider1 = slider)}
                            >
                                <div>Time Tracking</div>
                                <div>Daily Reporting</div>
                                <div>Toolbox Talks</div>
                                <div>
                                    Change Orders
                                    <div className="badge">soon</div>
                                </div>
                                <div>
                                    Time and Materials
                                    <div className="badge">soon</div>
                                </div>
                                <div>Fleet Management</div>
                            </Slider>

                            <Slider
                                className="slider"
                                {...settingsSlider}
                                asNavFor={this.state.nav1}
                                ref={(slider) => (this.slider2 = slider)}
                            >
                                <div className="icon">
                                    <div className="wrapper">
                                        <div className="img-wrapper">
                                            <img
                                                className="product-img"
                                                alt="clocked in"
                                                src={phoneTimeTracking1Img}
                                            />
                                            <img
                                                className="product-img time-card"
                                                alt="time cards"
                                                src={phoneTimeTracking2Img}
                                            />
                                        </div>
                                        <div className="text-wrapper">
                                            <h2>Time Tracking</h2>
                                            <p>
                                                This time tracking tool allows employees to easily
                                                record their hours completed for a specific project.
                                                While also including the core functions of other
                                                time tracking apps, Clock on the dot is specific for
                                                construction trades and includes features like labor
                                                codes.{' '}
                                            </p>
                                            <div className="bottom">
                                                <div className="wrap">
                                                    <div>
                                                        <svg
                                                            className="icon"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width="1000"
                                                            height="1000"
                                                            viewBox="0 0 1000 1000"
                                                        >
                                                            <path
                                                                fill="#8a8d8e"
                                                                d="M478.2,174.5c-61.4,7.4-115.9,44.5-144.8,98.7c-14.7,27.8-22.6,58-22.6,87.5c0,46.4,35.8,124.2,106.4,231
                                                        	c28.9,43.8,56.5,82,62.4,86.2c12,8.5,28.7,8.5,40.7,0c5.5-4,30.5-38.4,60.8-83.7c52.6-79,87.3-145,101.8-193.7
                                                        	c11.5-39.1,6.2-83.5-15.1-124.7c-21.1-41.1-57.6-73.8-100.2-89.9C539.3,175.3,506,171,478.2,174.5z M525.1,292.8
                                                        	c12.2,4.6,23.1,12.9,31.6,24.2c9.8,12.9,13.3,24.2,13.3,42.8c0,13.4-0.8,16.9-5.8,27.6c-7.3,15.5-19.3,27.5-34.7,34.7
                                                        	c-11.1,5.3-13.8,5.8-29.5,5.8c-15.6,0-18.3-0.5-29.5-5.8c-53.8-25.4-54.8-100-1.6-127C486,286.7,506,285.7,525.1,292.8z
                                                        	 M302.9,550.7c-50,16.7-81.3,34.2-106.8,59.9c-17.7,17.8-25.4,31.4-29.1,51.5c-5.9,32.3,12.1,66.5,49.5,94.3
                                                        	c18.5,13.6,53.1,30.5,83.1,40.6c137.7,45.6,330.1,38,445-18c28-13.6,41.3-22.7,59.1-40.7c17.7-17.8,25.6-32,29.3-51.6
                                                        	c7.8-42.3-24.3-85.6-86.5-116.1c-18-8.9-72.8-28.9-79-28.9c-0.9,0-4.9,5.7-9.1,12.8l-7.3,12.8l7.6,1.8
                                                        	c53.1,13.3,104.4,38.7,127.1,63.5c14.4,15.5,18.8,25.1,18.8,42c0,16.9-4.4,26.5-18.8,42c-38.2,41.3-126.6,71.5-234,80
                                                        	c-33.3,2.5-101.7,1.3-133.9-2.7c-110.4-13.5-195.8-51.5-218.2-97c-6.2-12.3-6.2-32.4,0-44.7c9.8-20.2,37.1-42.3,71.9-58.6
                                                        	c16-7.4,51.6-20,69.7-24.5l7.4-1.8l-7.3-12.8c-4-7.1-8.4-12.8-9.5-12.8C330.9,541.8,317.8,545.8,302.9,550.7z"
                                                            />
                                                        </svg>
                                                    </div>
                                                    <div>
                                                        <h3 className="padding-left">
                                                            Location Tracking
                                                        </h3>
                                                        <p>
                                                            The app allows the employer to track the
                                                            location of the employees while they are
                                                            on a specific job site
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="icon">
                                    <div className="wrapper">
                                        <div className="img-wrapper">
                                            <img
                                                className="product-img"
                                                alt="daily reporting dashboard"
                                                src={phoneDailyReporting1Img}
                                            />
                                            <img
                                                className="product-img time-card"
                                                alt="daily report"
                                                src={phoneDailyReporting2Img}
                                            />
                                        </div>
                                        <div className="text-wrapper">
                                            <h2>Daily Reporting</h2>
                                            <p>
                                                This section allows the user to track their progress
                                                throughout each day and includes work logs, daily
                                                notes, and daily surveys.{' '}
                                            </p>
                                            <div className="bottom">
                                                <div className="wrap">
                                                    <div>
                                                        <svg
                                                            className="icon small"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width={178}
                                                            height={178}
                                                            viewBox="0 0 178 178"
                                                        >
                                                            <path
                                                                fill="#8a8d8e"
                                                                d="M3703.52,2836.33a13.145,13.145,0,1,1-13.14,13.15,13.145,13.145,0,0,1,13.14-13.15m0,6.57a6.575,6.575,0,1,0,6.57,6.58,6.577,6.577,0,0,0-6.57-6.58m0,23c8.77,0,26.28,4.37,26.28,13.15v9.85h-52.57v-9.85c0-8.78,17.52-13.15,26.29-13.15m0,6.25c-9.76,0-20.04,4.79-20.04,6.9v3.61h40.08v-3.61C3723.56,2876.94,3713.28,2872.15,3703.52,2872.15Z"
                                                                transform="translate(-3614.06 -2776.41)"
                                                            />
                                                        </svg>
                                                    </div>
                                                    <div>
                                                        <h3>Work Logs</h3>
                                                        <p>
                                                            This category allows the user to report
                                                            how much time has been used on a project
                                                            in order to accurately estimate payroll
                                                            allocation according to specific job
                                                            type.
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="wrap">
                                                    <div>
                                                        <svg
                                                            className="icon small"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width={178}
                                                            height={178}
                                                            viewBox="0 0 178 178"
                                                        >
                                                            <path
                                                                fill="#8a8d8e"
                                                                d="M3668.99,2841.2v7.67h46.02v-7.67h-46.02m0,15.35v7.67h46.02v-7.67h-46.02m69.19,0.5a2.137,2.137,0,0,0-1.5.65l-3.83,3.84,7.86,7.87,3.83-3.84a2.037,2.037,0,0,0,0-2.96l-4.91-4.91a2.044,2.044,0,0,0-1.45-.65m-7.56,6.71-23.28,23.26v7.91h7.9l23.24-23.3-7.86-7.87m-61.63,8.14v7.68h30.68v-7.68h-30.68Z"
                                                                transform="translate(-3614.06 -2776.41)"
                                                            />
                                                        </svg>
                                                    </div>
                                                    <div>
                                                        <h3>Daily Notes</h3>
                                                        <p>
                                                            This feature allows the user to submit
                                                            observations and concerns regarding a
                                                            job site. Submissions are segmented into
                                                            three categories: Notes, Issues and
                                                            Concerns; Site Safety Observations; and
                                                            Quality Control Observations. In all
                                                            categories the user is able to include a
                                                            description and photo to better explain
                                                            the situation.
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="wrap">
                                                    <div>
                                                        <svg
                                                            className="icon small"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width={178}
                                                            height={178}
                                                            viewBox="0 0 178 178"
                                                        >
                                                            <path
                                                                fill="#8a8d8e"
                                                                d="M3725.27,2841.39h-13.21a9.773,9.773,0,0,0-8.97-6.46c-4.25,0-7.31,2.87-8.73,6.46h-12.98a6.387,6.387,0,0,0-6.37,6.46v44.77a6.338,6.338,0,0,0,6.37,6.22h44.13a6.387,6.387,0,0,0,6.37-6.46v-44.53a6.748,6.748,0,0,0-6.61-6.46m-21.94,0a3.152,3.152,0,0,1,3.07,3.11,3.367,3.367,0,0,1-3.07,3.35,3.145,3.145,0,0,1-3.07-3.11,3.374,3.374,0,0,1,3.07-3.35m-15.81,12.69h31.38v-6.23h6.37v44.77h-43.89v-44.77h6.37l-0.23,6.23m0.23,29.68v-6.46h31.39v6.46h-31.39m0-14.36v-6.47h31.39v6.47h-31.39"
                                                                transform="translate(-3614.06 -2776.41)"
                                                            />
                                                        </svg>
                                                    </div>
                                                    <div>
                                                        <h3>Daily Surveys</h3>
                                                        <p>
                                                            Based on labor codes and specific roles
                                                            of employees, users will be prompted to
                                                            fill out surveys required for specific
                                                            jobs.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="icon">
                                    <div className="wrapper">
                                        <div className="img-wrapper">
                                            <img
                                                className="product-img"
                                                alt="tool box talk dashboard"
                                                src={phoneToolBoxTalks1Img}
                                            />
                                            <img
                                                className="product-img time-card"
                                                alt="tool box talk pdf"
                                                src={phoneToolBoxTalks2Img}
                                            />
                                        </div>
                                        <div className="text-wrapper">
                                            <h2>Toolbox Talks</h2>
                                            <p>
                                                This feature provides safety information for
                                                employees. The users can create safety talks that
                                                can be easily accessible. Additionally, employees
                                                can read and sign off on all safety messages and
                                                requirements associated with each specific job. Once
                                                the employee signs the document, and it has been
                                                marked as done a PDF report will be generated
                                                summarizing all safety information for future
                                                reference.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="icon">
                                    <div className="wrapper">
                                        <div className="img-wrapper soon">
                                            <img
                                                className="product-img"
                                                alt="change order dashboard"
                                                src={phoneChangeOrder1Img}
                                            />
                                            <img
                                                className="product-img time-card"
                                                alt="change order sign off"
                                                src={phoneChangeOrder2Img}
                                            />
                                            <div className="coming-soon"></div>
                                        </div>
                                        <div className="text-wrapper">
                                            <h2>Change Orders</h2>
                                            <p>
                                                If the customer desires to change the scope of the
                                                work, they can request such changes through this
                                                feature. If approved, the labor codes will be
                                                updated accordingly.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="icon">
                                    <div className="wrapper">
                                        <div className="img-wrapper soon">
                                            <img
                                                className="product-img"
                                                alt="time and materials dashboard"
                                                src={phoneTimeAndMaterials1Img}
                                            />
                                            <img
                                                className="product-img time-card"
                                                alt="time and materials items"
                                                src={phoneTimeAndMaterials2Img}
                                            />
                                            <div className="coming-soon"></div>
                                        </div>
                                        <div className="text-wrapper">
                                            <h2>Time and Materials</h2>
                                            <p>
                                                Through this component, users can create time and
                                                material. Employees can clock in directly to a
                                                specific time and material, and they can track each
                                                item that is being used for each specific job.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="icon">
                                    <div className="wrapper">
                                        <div className="img-wrapper">
                                            <img
                                                className="product-img"
                                                alt="card accident step 1"
                                                src={phoneAccident1Img}
                                            />
                                            <img
                                                className="product-img time-card"
                                                alt="card accident step 2"
                                                src={phoneAccident2Img}
                                            />
                                        </div>
                                        <div className="text-wrapper">
                                            <h2>Fleet Management</h2>
                                            <p>
                                                If an automobile accident occurs, employees can use
                                                this component to report the incident. A full crash
                                                report is available to be filled out.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </Slider>
                        </div>
                    </section>
                    <section className="benefits">
                        <div className="container">
                            <div className="wrap">
                                <div className="wrapper">
                                    <div className="img-wrapper">
                                        <AnimatedIcon icon="icon-field-access" />
                                    </div>
                                    <div className="text-wrapper">
                                        <h3>Field Access</h3>
                                        <p>
                                            The app allows field techs to communicate with the
                                            office without calling or going into the office.
                                        </p>
                                    </div>
                                </div>
                                <div className="wrapper">
                                    <div className="img-wrapper">
                                        <AnimatedIcon icon="icon-consolidated-reporting" />
                                    </div>
                                    <div className="text-wrapper">
                                        <h3>Consolidated Reporting</h3>
                                        <p>
                                            Web-based admin console allows office staff to easily
                                            track and communicate with the field techs.
                                        </p>
                                    </div>
                                </div>
                                <div className="wrapper">
                                    <div className="img-wrapper">
                                        <AnimatedIcon icon="icon-data-integration" />
                                    </div>
                                    <div className="text-wrapper">
                                        <h3>Data Integration</h3>
                                        <p>
                                            The admin console allows data to be easily exported and
                                            supports APIs into common software platforms.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="testimonials">
                        <div className="container">
                            <div className="wrap">
                                <h2>Three apps in one!</h2>
                                <div className="wrapper">
                                    <p>
                                        The Clock on the dot App simplified the tools we were using
                                        in the field and saved us money. Instead of our field techs
                                        using 3 app we got all the features in one app. Best
                                        solution I’ve seen!
                                    </p>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="btn-wrapper">
                        <div className="container">
                            <div className="wrapper">
                                <div className="actions-wrapper">
                                    <div>
                                        <small>Or download it from </small>
                                        <div className="buttons-wrapper">
                                            <a
                                                className="store-btn"
                                                href="/"
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                <img
                                                    className="product-img"
                                                    alt="google play store"
                                                    src={googleImg}
                                                />
                                            </a>
                                            <a
                                                className="store-btn"
                                                href="/"
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                <img
                                                    className="product-img"
                                                    alt="app store"
                                                    src={appleImg}
                                                />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <Footer />
                </main>
            </>
        );
    }
}

export default Home;
